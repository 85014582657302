<template>
  <hs-sidebar class="position-relative" :opened="sidebarOpened">
    <template v-slot:content data-track="funnel stage settings">
      <div class="mt-lg-2 px-3 px-md-2 px-lg-0 py-5" :class="{ 'content mr-auto ml-auto': !isLoading }">
        <hsLoading v-if="isLoading" />
        <div v-else-if="!isLoading && loadedFunnel">
          <FunnelName />
          <div class="d-flex flex-column mt-4 mt-md-2 pt-md-5">
            <div class="mb-4 d-flex flex-column-reverse flex-md-row justify-content-between">
              <span class="pages-and-emails d-flex align-items-center font-weight-bold lh-150">
                {{ $t(`${prefix}.pages_and_emails`) }}
              </span>
              <FunnelStatus class="pb-5 pb-md-0" />
            </div>
            <div class="w-100 d-flex flex-column">
              <div
                class="custom-collapse w-100 d-flex pb-3 position-relative"
                v-for="(stage, index) in loadedFunnel.stages"
                :key="`custom-collapse-${index}`"
              >
                <div
                  class="index w-100 h-100 mr-4 rounded-circle d-flex align-items-center justify-content-center font-weight-bold lh-150"
                >
                  {{ index + 1 }}
                </div>
                <CustomCollapse
                  class="w-100 d-none d-sm-block"
                  :collapsed="index === 0"
                  @shown="shown(index)"
                  :published="stage.isPublished"
                  :title="stage.name"
                  :isEdited="statusStagesEmails(stage.id)"
                  :type="stage.type"
                  :url="stage.url"
                >
                  <CollapseContent :funnel="loadedFunnel" :stage="stage" :alreadyOpened="alreadyOpened[index]" />
                </CustomCollapse>
                <Card
                  class="d-block d-sm-none"
                  :title="stage.name"
                  :published="stage.isPublished"
                  @selected="onStageSelected(stage.id)"
                />
              </div>
              <div class="w-100 d-flex pb-3 custom-collapse">
                <div class="index position-relative mr-4 rounded-circle">
                  <hs-icon class="position-absolute check-icon-position" icon="check" size="28" />
                </div>
                <PublishingCard class="publishing-card" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:sidebar>
      <SidebarContent v-if="loadedFunnel" @hide="onDetailHidden" :funnel="loadedFunnel" :stage="selectedStage" />
    </template>
  </hs-sidebar>
</template>

<script>
import { isEmpty } from 'lodash';
import ToastHelper from '@/shared/helpers/toast';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Card from './components/Card';
import CustomCollapse from './components/CustomCollapse';
import FunnelName from './components/FunnelName';
import FunnelStatus from './components/FunnelStatus';
import PublishingCard from './components/PublishingCard';
import { hsLoading, hsSidebar } from '@/components';
import { pagesService } from '@/services';
import CollapseContent from './CollapseContent';
import EmailTemplate from '@/sparkfunnels/mixins/EmailTemplate';
import { CheckoutStages } from '@/libs/funnel';

export default {
  name: 'EditFunnel',
  mixins: [EmailTemplate],
  components: {
    Card,
    CustomCollapse,
    FunnelName,
    FunnelStatus,
    hsSidebar,
    hsLoading,
    PublishingCard,
    CollapseContent,
    SidebarContent: () => import('./SidebarContent'),
  },
  computed: {
    ...mapGetters('jwt', ['isLoaded']),
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
    ...mapState('jwt', {
      jwt: state => state.encoded,
    }),
    prefix() {
      return 'sparkfunnels.funnels.edit';
    },
  },
  data() {
    return {
      alreadyOpened: [],
      isLoading: false,
      sidebarOpened: false,
      selectedStage: {},
      emails: [],
      allEditedEmails: false,
    };
  },
  watch: {
    loadedFunnel: function({ stages }) {
      if (stages.length > 0) {
        this.mountStagesEmailContent(stages);
      }
    },
  },
  methods: {
    ...mapActions('funnel', ['loadFunnel']),
    ...mapActions('jwt', ['load']),
    ...mapMutations('funnel', ['setStagesFromLoadedFunnel']),
    fetchFunnel() {
      this.isLoading = true;
      this.loadFunnel(this.$route.params.funnelId)
        .then(() => {
          this.mountIsPublished();
          this.alreadyOpened = this.loadedFunnel.stages.map((_, index) => index === 0);
        })
        .catch(() => {
          this.$router.push({ name: 'FunnelsList' });
          ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.edit.toast.load_funnel_error'));
        })
        .finally(() => (this.isLoading = false));
    },
    async loadJwtToken() {
      if (!this.isLoaded) {
        await this.load();
      }
    },
    async mountIsPublished() {
      const promises = this.loadedFunnel.stages.map(async stage => {
        const isPublished = stage.page_id
          ? await pagesService.isPagePublished({ jwt: this.jwt, pageId: stage.page_id })
          : CheckoutStages.is(stage.type) && !isEmpty(stage.url);
        return { ...stage, isPublished };
      });
      const stages = await Promise.all(promises);
      this.setStagesFromLoadedFunnel(stages);
    },
    onDetailHidden() {
      this.sidebarOpened = false;
      this.selectedStage = {};
    },
    onStageSelected(id) {
      this.selectedStage = this.loadedFunnel.stages.find(stage => stage.id === id);
      this.sidebarOpened = true;
    },
    shown(index) {
      this.$set(this.alreadyOpened, index, true);
    },
  },
  async created() {
    await this.loadJwtToken();
    this.fetchFunnel();
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: $screen-bs-md) {
  .content {
    max-width: 876px;
  }
}
.pages-and-emails {
  font-size: 12px;
  color: $grey-25;
}

.index {
  margin-top: 17px;
  max-width: 28px;
  min-width: 28px;
  max-height: 28px;
  min-height: 28px;
  background: $white;
  @media only screen and (min-width: $screen-bs-sm) {
    background: $light-blue;
  }
  box-shadow: 0px 2px 4px rgba($cyan, 0.2);
  font-size: 14px;
}

.custom-collapse {
  &:last-child {
    padding-bottom: 0 !important;
  }

  &:not(:last-child)::before {
    content: '';
    height: calc(100% - 25px);
    border-left: 1px solid $grey-25;
    position: absolute;
    left: 14px;
    top: 45px;
  }
}

.check-icon-position {
  right: -4px;
  top: -4px;
}

.publishing-card {
  width: calc(100% - 52px);
}
</style>
