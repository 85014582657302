<template>
  <div v-if="loadedMedia" class="d-flex flex-column">
    <SectionContainer icon="video" :title="$t(`${prefix}.title`)">
      <hs-loading v-if="isLoading" />
      <template v-else slot="container">
        <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center">
          <div class="broadcast-container w-100 h-100 mb-0 d-flex flex-column rounded mr-sm-4 px-3 px-sm-0">
            <div class="broadcast w-100 rounded-top d-flex align-items-center justify-content-center bg-white">
              <hs-icon variant="light" icon="video-plus" class="text-primary video-icon" />
            </div>
            <hs-button id="broadcast-button" variant="primary" v-b-modal="currentBroadcast.modalId">
              {{ currentBroadcast.button }}
            </hs-button>
          </div>
          <div class="webinar-info my-4 my-sm-0">
            <div class="transmission">
              <p
                class="text-break h5 mb-3"
                v-html="
                  $t(`${prefix}.card.title`, {
                    title: loadedMedia.title,
                    date,
                    time,
                  })
                "
              ></p>
            </div>
            <div class="link mr-3">
              <p class="mb-0">
                {{ $t(`${prefix}.card.link`) }}
                <a
                  id="broadcast-viewer-link"
                  class="text-primary w-100"
                  :href="broadcastViewerLinkFromFunnelStage(funnel, stage)"
                  target="_blank"
                  @click="trackPageVisited"
                >
                  <u class="text-break">{{ broadcastViewerLinkFromFunnelStage(funnel, stage) }}</u>
                </a>
              </p>
            </div>
            <div class="actions d-flex align-items-sm-start ml-sm-3">
              <hs-button
                id="reset-webinar"
                variant="link"
                class="px-0 text-danger font-weight-normal"
                v-b-modal="resetModalId"
              >
                <hs-icon icon="trash-alt" size="17" />
              </hs-button>
              <hs-button
                id="edit-webinar"
                variant="link"
                class="px-0 text-dark font-weight-normal ml-3"
                v-b-modal.webinar-edit
              >
                <hs-icon icon="pencil" size="17" />
              </hs-button>
            </div>
          </div>
        </div>
        <hsConfirmModal
          :id="resetModalId"
          icon="undo"
          variant="cherry"
          icon-variant="light"
          header-class="border-0"
          :customTitle="$t(`${prefix}.modals.clear_custom_webinar.title`)"
          :description="$t(`${prefix}.modals.clear_custom_webinar.description`)"
          :ok-title="$t(`${prefix}.modals.clear_custom_webinar.ok_title`)"
          :cancel-title="$t(`${prefix}.modals.clear_custom_webinar.cancel_title`)"
          :verificationText="$t(`${prefix}.modals.clear_custom_webinar.verification_text`)"
          :placeholder="$t(`${prefix}.modals.clear_custom_webinar.verification_text`)"
          @ok="resetWebinar"
        />
        <WebinarEdit :media="loadedMedia" :stage="stage" />
        <HerosparkBroadcast :webinarMedia="loadedMedia" :funnel="funnel" :stage="stage" />
        <ThirdPartyBroadcast :funnel="funnel" :stage="stage" />
      </template>
    </SectionContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import WebinarEdit from './Edit';
import HerosparkBroadcast from './Broadcast/Herospark';
import ThirdPartyBroadcast from './Broadcast/ThirdParty';
import { hsConfirmModal, hsLoading } from '@/components';
import { format } from '@/shared/helpers/dates';
import ToastHelper from '@/shared/helpers/toast';
import SectionContainer from '../SectionContainer';
import { analyticsService } from '@/services';

export default {
  name: 'FunnelsWebinarSection',
  components: {
    WebinarEdit,
    HerosparkBroadcast,
    ThirdPartyBroadcast,
    hsConfirmModal,
    hsLoading,
    SectionContainer,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('funnel', ['broadcastViewerLinkFromFunnelStage', 'isWebinarHerospark']),
    ...mapState('funnel', ['loadedMedia']),
    date() {
      return this.loadedMedia.start_at
        ? format(this.loadedMedia.start_at, this.$t('date.format'))
        : this.$t('date.clear');
    },
    time() {
      return this.loadedMedia.start_at ? format(this.loadedMedia.start_at, this.$t('HH:mm')) : this.$t('hour.clear');
    },
    currentBroadcast() {
      if (this.loadedMedia && this.loadedMedia.type === 'Webinar') {
        return {
          button: this.$t('sparkfunnels.funnels.edit.buttons.broadcast_herospark'),
          modalId: 'webinar-herospark-broadcast',
        };
      } else {
        return {
          button: this.$t('sparkfunnels.funnels.edit.buttons.broadcast_third_party'),
          modalId: 'webinar-third-party-broadcast',
        };
      }
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar';
    },
    resetModalId() {
      return `reset-webinar-${this._uid}`;
    },
  },
  methods: {
    ...mapActions('funnel', ['updateMedia', 'getMedia']),
    resetWebinar() {
      const media = {
        title: this.$t(`sparkfunnels.funnels.stages.types.Funnel::WebinarStage`),
        type: 'Webinar',
        text: null,
        start_at: null,
        end_at: null,
      };
      this.isLoading = true;
      this.updateMedia({ mediaId: this.stage.media_id, media })
        .then(() => ToastHelper.successMessage(this.$t(`${this.prefix}.actions.update.success`)))
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.actions.update.error`)))
        .finally(() => {
          this.isLoading = false;
          this.trackResetWebinar();
        });
    },
    trackPageVisited() {
      analyticsService.track({
        event: 'Webinar Page Visited',
        props: {
          funnel_id: this.stage.funnel_id,
          webinar_page_visit_source: 'Tela - Edição Etapa Webinar',
        },
      });
    },
    trackResetWebinar() {
      analyticsService.track({
        event: 'Webinar Tool Redefined',
        props: {
          funnel_id: this.funnel.id,
        },
      });
    },
  },
  created() {
    this.getMedia({ mediaId: this.stage.media_id });
  },
};
</script>

<style lang="scss" scoped>
.broadcast {
  height: 91px;
  border: 2px solid $grey-10;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
}

.video-icon {
  font-size: 50px;
}

.webinar-info {
  display: grid;
  grid-template-areas: 'transmission transmission' 'link actions';
}

.transmission {
  grid-area: transmission;
}

.link {
  grid-area: link;
}

.actions {
  grid-area: actions;
}

@media only screen and (min-width: $screen-bs-sm) {
  .webinar-info {
    grid-template-areas: 'transmission actions' 'link actions';
  }

  .broadcast-container {
    width: 50% !important;
  }
}
</style>
