<template>
  <div class="w-100">
    <hs-button
      variant="link"
      class="btn-card mr-0 mb-2 mr-md-2 p-4 bg-white shadow-sm w-100 rounded-lg text-left text-dark border border-white d-flex align-items-center justify-content-between"
      :disabled="disabled"
      @click="$emit('selected')"
    >
      <div class="d-flex flex-column">
        {{ title }}
        <PublishmentStatus :published="published" class="mt-2 pt-1" />
      </div>
      <hs-icon icon="chevron-right" class="icon" size="16" />
    </hs-button>
  </div>
</template>

<script>
import PublishmentStatus from './PublishmentStatus';

export default {
  props: {
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    published: {
      type: Boolean,
    },
  },
  components: {
    PublishmentStatus,
  },
};
</script>

<style lang="scss" scoped>
.btn-card {
  border-width: 1px !important;
}
.icon {
  color: $grey-32;
}
</style>
