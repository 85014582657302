<template>
  <div class="w-100 px-3 px-md-4">
    <Checkout v-bind="[$attrs, $props]" />
    <EmailTemplates v-bind="[$attrs, $props]" :funnel="funnel" v-if="isDirectOfferFunnel" />
  </div>
</template>

<script>
import EmailTemplates from '../sections/EmailTemplates';
import Checkout from '../sections/Checkout';

export default {
  components: {
    Checkout,
    EmailTemplates,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDirectOfferFunnel() {
      return this.funnel.name !== 'Funil de oferta direta';
    },
  },
};
</script>
