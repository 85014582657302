







import Vue from 'vue';

interface Email {
  created_at: Date;
  updated_at: Date;
}

export default Vue.extend({
  name: 'MessageEmailNotEdited',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'E-mail não editado',
    },
    icon: {
      type: String,
      default: 'exclamation-circle',
    },
  },
});
