<template>
  <div class="min-w-0 collapse-content">
    <b-button
      id="collapse-btn"
      block
      v-b-toggle="id"
      class="tlw-text-left tlw-mb-0 tlw-py-6 tlw-px-6 btn-collapse h6 lg:tlw-py-4 d-flex tlw-flex-auto justify-content-between tlw-items-center tlw-relative"
    >
      <h5 class="tlw-pr-2 md:tlw-pr-0 tlw-font-bold text-dark text-truncate lh-150">{{ title }}</h5>
      <div class="tlw-flex tlw-items-center">
        <MessageEmailNotEdited :show="isEdited" />
        <PublishmentStatus :published="published" class="tlw-pr-2 tlw-mr-6" v-if="!isCheckout(type)" />
        <CheckoutStatus :url="url" class="tlw-mr-6 tlw-pr-2" v-else />
        <hs-icon class="icon text-dark" variant="light" icon="chevron-up" v-bind:class="{ collapsed: visible }" />
      </div>
    </b-button>
    <b-collapse :id="id" v-model="visible" @shown="$emit('shown')">
      <div class="tlw-p-6 b-collapse sm:tlw-pb-0">
        <slot></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import PublishmentStatus from './PublishmentStatus';
import MessageEmailNotEdited from '@/sparkfunnels/components/MessageEmailNotEdited';
import CheckoutStatus from '@/sparkfunnels/components/CheckoutStatus';
import { mapGetters } from 'vuex';

export default {
  components: {
    PublishmentStatus,
    MessageEmailNotEdited,
    CheckoutStatus,
  },
  data() {
    return {
      visible: this.collapsed,
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
    published: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('funnel', ['getCheckoutStage']),
    id() {
      return this._uid.toString();
    },
  },
  methods: {
    isCheckout(value) {
      return this.getCheckoutStage.includes(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: 0.3s transform ease-in-out;
}

.collapsed .icon {
  transform: rotate(180deg);
}

.collapse-content {
  border: 3px solid $grey-10;
}

.btn-collapse {
  color: $grey-30;
  background: $light-blue;
  border-radius: 4px 4px 0px 0px !important;
  box-shadow: 0px 2px 4px rgba($cyan, 0.2);
  &:active {
    background-color: $light-blue !important;
  }

  &:active:focus {
    box-shadow: 0px 2px 4px rgba($cyan, 0.2) !important;
  }
}

.b-collapse {
  background-color: $white;
}

.min-w-0 {
  min-width: 0;
}

.publish-container {
  color: $green-dark;
}
</style>
