<template>
  <div class="d-flex flex-column">
    <h4 class="text-center font-weight-bold mb-5">
      {{ $t(`${prefix}.third_party_config.title`) }}
    </h4>
    <hs-group>
      <label class="mb-0" v-html="$t(`${prefix}.third_party_config.form.code.label`)" />
      <b-form-textarea
        id="media-text-input"
        v-model="text"
        :placeholder="$t(`${prefix}.third_party_config.form.code.placeholder`)"
        rows="3"
        spellcheck="false"
        max-rows="4"
      ></b-form-textarea>
    </hs-group>
    <p
      class="third-party-info border-left border-primary pl-3 mb-0"
      v-html="$t(`${prefix}.third_party_config.info`)"
    ></p>
  </div>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text: {
      get: function() {
        return this.media.text;
      },
      set: function(newText) {
        this.$emit('change', { text: newText });
        this.value = newText;
      },
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.edit';
    },
  },
};
</script>

<style lang="scss" scoped>
.third-party-info {
  border-width: 3px !important;
}

/deep/ label {
  font-size: 14px;
  font-weight: bold;
}
</style>
