<template>
  <section>
    <PageHeader
      :title="$t('sparkfunnels.lead_manager.list.page-header.title')"
      :subTitle="
        $t('sparkfunnels.lead_manager.list.page-header.sub-title', {
          total: totalLeads,
        })
      "
      backText="Voltar para funis"
      backRoute="FunnelsList"
      :backOnlyMobile="false"
    >
    </PageHeader>
    <section v-if="isLoading">
      <hsLoading class="my-5" />
    </section>
    <section class="py-4 px-5 d-flex flex-row" v-else>
      <div class="w-100 position-relative">
        <div class="tlw-flex flex-lg-nowrap flex-md-nowrap flex-wrap mb-3">
          <LeadCard :linkForLeads="false" title="Meus leads" :theAmount="theAmount" :theLimite="theLimite" />
          <CardPlan
            :title="$t('my-account.views.plan.card.title')"
            :subTitle="$t('my-account.views.plan.card.description')"
            v-if="theLimite"
          />
        </div>
        <hs-group v-if="totalLeads" class="col-md-4 p-0">
          <label class="font-weight-bold mb-0" for="query">
            {{ $t('sparkfunnels.funnels.leads.search.label') }}
          </label>
          <hs-input
            id="query"
            data-testid="query-input"
            :placeholder="$t('sparkfunnels.funnels.leads.search.placeholder')"
            @input="searchLeads"
          />
        </hs-group>
        <hsLoading v-if="isLoading" class="w-100" />
        <template v-else>
          <NoTableData
            v-if="!totalLeads"
            class="mb-3"
            :title="$t('sparkfunnels.funnels.leads.no_leads.title')"
            :description="$t('sparkfunnels.funnels.leads.no_leads.description')"
            :buttonTitle="$t('sparkfunnels.funnels.leads.no_leads.button')"
            @action-clicked="$router.push({ name: 'FunnelsList' })"
          >
            <Table />
          </NoTableData>
          <template v-else-if="!leads.length && query">
            <div class="no-results d-flex flex-column mx-auto mt-5 pt-5 align-items-center text-center">
              <b-img-lazy
                :src="require('@/assets/images/SparkFunnels/LeadManager/astronaut.svg')"
                class="mb-4"
                :alt="$t('sparkfunnels.funnels.leads.no_results.img_alt')"
              />
              <p class="font-weight-bold h4">{{ $t('sparkfunnels.funnels.leads.no_results.title') }}</p>
              <p>{{ $t('sparkfunnels.funnels.leads.no_results.description') }}</p>
            </div>
          </template>
          <div id="leads-table" v-else class="d-flex flex-column">
            <Table class="mb-3 flex-grow-1" :items="leads" :leadsLength="totalRows" />
            <hs-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :go-page-text="$t('sparkfunnels.funnels.list.go_page_text')"
              :per-page="perPage"
              align="center"
            ></hs-pagination>
          </div>
        </template>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { hsLoading } from '@/components';
import Table from './components/Table';
import NoTableData from '@/components/NoTableData';
import debounce from 'lodash.debounce';
import { funnelApi } from '@/services';
import PageHeader from '@/components/_structures/PageHeader.vue';
import LeadCard from './LeadCard/LeadCard';
import CardPlan from '../Funnels/List/components/CardPlan.vue';
import heimdallService from '@/services/heimdall/newPlans';

export default {
  name: 'Leads',
  components: {
    hsLoading,
    Table,
    NoTableData,
    PageHeader,
    CardPlan,
    LeadCard,
  },
  props: {
    funnelId: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      leads: [],
      currentPage: 1,
      perPage: 8,
      totalPages: 0,
      totalRows: 0,
      totalLeads: 0,
      query: null,
      theAmount: 0,
      theLimite: 0,
    };
  },
  computed: {
    ...mapState('funnel', ['loadedFunnel']),
  },
  methods: {
    ...mapActions('funnel', ['loadFunnel', 'getFunnelsLimit']),
    fetchFunnelLeads() {
      this.isLoading = true;
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        funnelId: this.funnelId,
        query: this.query,
      };
      funnelApi
        .getFunnelLeads(params)
        .then(({ data }) => {
          this.leads = data.leads;
          this.totalPages = data.total_pages;
          this.totalRows = data.total_count;
          this.totalLeads = this.totalRows > this.totalLeads ? this.totalRows : this.totalLeads;
        })
        .catch(() => this.$router.push({ name: 'FunnelsList' }))
        .finally(() => (this.isLoading = false));
    },
    async showLeadCard() {
      this.isLoading = true;
      try {
        const { funnels, leads } = await this.getFunnelsLimit();
        this.theAmount = leads || 0;

        const {
          data: { limit_leads },
        } = await heimdallService.getFeatureLimit();
        this.theLimite = limit_leads || 0;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    if (!this.loadedFunnel) this.loadFunnel(this.funnelId);
    this.fetchFunnelLeads();
    this.showLeadCard();
    this.searchLeads = debounce(queryStr => {
      this.query = queryStr;
      this.currentPage = 1;
      this.fetchFunnelLeads();
    }, 300);
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != oldPage && newPage <= this.totalPages) {
        this.fetchFunnelLeads();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  max-width: 300px;
}
</style>
