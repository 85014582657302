<template>
  <b-modal
    :id="modalId"
    centered
    ok-only
    footer-class="d-flex justify-content-center border-0 py-4"
    body-class="px-3 pt-4 pb-0 px-md-5 pt-md-5"
    ok-variant="link"
    :ok-title="$t(`${prefix}.herospark.ok_title`)"
    :title="$t(`${prefix}.title`)"
    size="lg"
  >
    <h3 class="text-center font-weight-bold mb-3">
      {{ $t(`${prefix}.herospark.title`) }}
    </h3>
    <p class="text-center mb-5">{{ $t(`${prefix}.herospark.description`) }}</p>
    <div class="d-flex flex-column flex-lg-row">
      <b-card
        class="broadcast-card d-flex flex-column justify-content-center align-items-center mb-4 mb-lg-0 mr-lg-3"
        body-class="p-4"
      >
        <hs-icon class="text-center text-primary d-block mb-2 h2 font-weight-light" icon="cog" />
        <p class="text-center font-weight-bold mb-3">
          {{ $t(`${prefix}.herospark.cards.configuration.title`) }}
        </p>
        <p class="broadcast-description text-center mb-4 h6">
          {{ $t(`${prefix}.herospark.cards.configuration.description`) }}
        </p>
        <hs-button variant="outline-primary" block @click="openTransmitterLink">
          {{ $t(`${prefix}.herospark.cards.configuration.button`) }}
        </hs-button>
      </b-card>
      <b-card class="broadcast-card d-flex flex-column justify-content-center align-items-center" body-class="p-4">
        <hs-icon class="text-center text-primary d-block mb-2 h2 font-weight-light" icon="tv" />
        <p class="text-center font-weight-bold mb-3">
          {{ $t(`${prefix}.herospark.cards.test.title`) }}
        </p>
        <p class="broadcast-description text-center mb-4 h6">
          {{ $t(`${prefix}.herospark.cards.test.description`) }}
        </p>
        <hs-button
          variant="outline-primary"
          block
          @click="
            openViewerLink();
            track();
          "
        >
          {{ $t(`${prefix}.herospark.cards.test.button`) }}
        </hs-button>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { analyticsService } from '@/services';

export default {
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
    webinarMedia: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('jwt', {
      jwt: state => state.encoded,
    }),
    ...mapGetters('funnel', ['broadcastViewerLinkFromFunnelStage']),
    ...mapGetters('school', ['getDomainWithHttp']),
    modalId() {
      return 'webinar-herospark-broadcast';
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.broadcast';
    },
  },
  methods: {
    ...mapActions('jwt', ['load']),
    ...mapGetters('jwt', ['isLoaded']),
    async openTransmitterLink() {
      if (!this.isLoaded()) {
        await this.load();
      }
      window.open(
        `${process.env.VUE_APP_WEBINAR_BASE_URL}?id=${this.webinarMedia.host_key}&token=${this.jwt}`,
        '_blank'
      );
    },
    openViewerLink() {
      window.open(this.broadcastViewerLinkFromFunnelStage(this.funnel, this.stage), '_blank');
    },
    track() {
      analyticsService.track({
        event: 'Webinar Page Visited',
        props: {
          funnel_id: this.funnel.id,
          webinar_page_visit_source: 'Modal - Transmitir Webinar',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.broadcast-card {
  border: 2px solid $grey-17;
}

.broadcast-description {
  color: $grey-32 !important;
  line-height: 150%;
}
</style>
