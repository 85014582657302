<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="mb-3">{{ $t('sparkfunnels.analytics.resume') }}</h5>
      </div>
    </div>
    <div class="row cards-row">
      <div class="col-lg-3">
        <hs-card-analytics
          id="revenues-card"
          class="h-100"
          :symbol="$t('money.symbol')"
          :label="$t('sparkfunnels.analytics.cards.revenues')"
          :value="incomeCount"
          icon="badge-dollar"
        />
      </div>
      <div class="col col-lg-6">
        <hs-card-analytics class="h-100 justify-content-between">
          <div class="px-4">
            <span class="d-block leading-tight text-sm font-weight-bold text-primary">
              <hs-icon icon="user" />
            </span>
            <span class="d-block leading-tight text-4xl font-weight-bold">{{ leadsCount }}</span>
            <span class="d-block leading-tight text-sm">{{ $t('sparkfunnels.analytics.cards.leads_count') }}</span>
          </div>

          <div class="px-4">
            <span class="d-block leading-tight text-sm font-weight-bold text-primary">
              <hs-icon icon="chart-line" />
            </span>
            <span class="d-block leading-tight text-4xl font-weight-bold">{{ salesCount }}</span>
            <span class="d-block leading-tight text-sm">{{ $t('sparkfunnels.analytics.cards.sales') }}</span>
          </div>

          <div class="px-4">
            <span class="d-block leading-tight text-sm font-weight-bold text-primary">
              <hs-icon icon="funnel-dollar" />
            </span>
            <span class="d-block leading-tight text-4xl font-weight-bold">{{ conversionRate }}</span>
            <span class="d-block leading-tight text-sm">{{ $t('sparkfunnels.analytics.cards.conversion') }}</span>
          </div>
        </hs-card-analytics>
      </div>
      <div class="col-lg-3">
        <hs-card-analytics
          id="average-converstion-card"
          class="h-100"
          :label="$t('sparkfunnels.analytics.cards.average-conversion')"
          :value="averageConversion"
          icon="check-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    conversionRate() {
      if (!this.funnel.conversion_rate) return '-';
      return `${Math.round((this.funnel.conversion_rate * 100) / 100)}%`;
    },
    incomeCount() {
      if (this.funnel.income_count === 0) return '-';
      return this.$options.filters.currency(this.funnel.income_count / 100, {
        symbol: '',
        thousandsSeparator: this.$t('money.thousands_separator'),
        fractionSeparator: this.$t('money.fraction_separator'),
      });
    },
    leadsCount() {
      if (this.funnel.leads_count === 0) return '-';
      return this.funnel.leads_count;
    },
    salesCount() {
      if (this.funnel.sales_count === 0) return '-';
      return this.funnel.sales_count;
    },
  },
  data() {
    return {
      averageConversion: '-',
    };
  },
  props: {
    funnel: {
      required: true,
    },
  },
  created() {
    const sumConversion = this.funnel.stages.reduce((prevVal, stage) => {
      return prevVal + stage.conversion_rate;
    }, 0);
    if (sumConversion > 0) this.averageConversion = `${String((sumConversion / this.funnel.stages.length) * 100)}%`;
  },
};
</script>

<style lang="scss" scoped>
.cards-row {
  .hs-card-analytics > div {
    padding: 20px 0;
  }
  .text-4xl,
  .text-sm {
    color: $grey;
  }
  .text-4xl {
    font-size: 32px;
    letter-spacing: -0.53px;
    line-height: 40px;
  }
  .text-sm {
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 20px;
  }
}
</style>
