<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      footer-class="broadcast-footer d-flex flex-column-reverse justify-content-center border-0 flex-md-row pb-4"
      body-class="px-3 pt-4 pb-0 py-md-5 mb-md-4"
      ok-variant="primary"
      cancel-variant="link"
      :ok-title="$t(`${prefix}.third_party.ok_title`)"
      :cancel-title="$t(`${prefix}.third_party.cancel_title`)"
      :title="$t(`${prefix}.title`)"
      size="lg"
      @ok="
        openBroadcastViewerLinkAndHide();
        track();
      "
    >
      <h3 class="text-center font-weight-bold mb-3">
        {{ $t(`${prefix}.third_party.title`) }}
      </h3>
      <p class="text-center mb-5">{{ $t(`${prefix}.third_party.description`) }}</p>
      <div class="d-flex flex-column flex-md-row">
        <div
          v-for="key in Object.keys(this.$t(`${prefix}.third_party.cards`))"
          :key="key"
          class="broadcast-info d-flex flex-column align-items-center px-4 mb-5 mb-md-0 mr-md-4 px-md-0"
        >
          <hs-icon
            :icon="$t(`${prefix}.third_party.cards.${key}.icon`)"
            class="text-primary mb-2 h2 font-weight-light"
          />
          <p class="text-center mb-0">
            {{ $t(`${prefix}.third_party.cards.${key}.description`) }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { analyticsService } from '@/services';

export default {
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('funnel', ['broadcastViewerLinkFromFunnelStage']),

    modalId() {
      return 'webinar-third-party-broadcast';
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.broadcast';
    },
  },
  methods: {
    openBroadcastViewerLinkAndHide() {
      window.open(this.broadcastViewerLinkFromFunnelStage(this.funnel, this.stage), '_blank');
      this.$bvModal.hide(this.modalId);
    },
    track() {
      analyticsService.track({
        event: 'Webinar Page Visited',
        props: {
          funnel_id: this.funnel.id,
          webinar_page_visit_source: 'Modal - Transmitir Webinar',
        },
      });
    },
  },
};
</script>

<style lang="scss">
.broadcast-info:last-child {
  margin-right: 0 !important;
}

.broadcast-footer {
  button {
    min-height: 45px;
  }

  @media only screen and (min-width: $screen-bs-md) {
    button {
      min-height: auto;
    }
  }
}
</style>
