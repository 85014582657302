<template>
  <div>
    <hs-table :responsive="true" :hover="true" :fields="fields" :items="items" fixed>
      <template slot="number" slot-scope="row">
        <section class="float-left">
          <span class="custom-text-table number-table">{{ row.item.number }}</span>
        </section>
      </template>
      <template slot="name" slot-scope="row">
        <section class="float-left">
          <span class="custom-text-table name-table">{{ row.item.name }}</span>
        </section>
      </template>
      <template slot="phone" slot-scope="row">
        <section class="float-left">
          <span class="name-table">{{ row.item.phone ? row.item.phone : '-' }} </span>
        </section>
      </template>
      <template slot="created_at" slot-scope="row">
        <section class="float-left">
          <span class="name-table" v-if="row.item.created_at">{{
            format(row.item.created_at, $t('date.format'))
          }}</span>
        </section>
      </template>
    </hs-table>
  </div>
</template>

<script>
import { format } from '@/shared/helpers/dates';

export default {
  name: 'leads-table',
  props: {
    items: {
      default: () => [{}, {}, {}, {}],
    },
    leadsLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('sparkfunnels.funnels.leads.table.fields.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('sparkfunnels.funnels.leads.table.fields.email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('sparkfunnels.funnels.leads.table.fields.phone'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('sparkfunnels.funnels.leads.table.fields.date'),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    format,
  },
};
</script>

<style lang="scss" scoped>
.custom-text-table {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 20px;
}
.number-table {
  color: $cyan;
}
.name-table {
  color: $grey;
}
</style>
