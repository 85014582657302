var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('hs-icon',{staticClass:"icon mb-2",class:( _obj = {}, _obj[("text-" + _vm.statusVariant)] = true, _obj ),attrs:{"icon":_vm.statusIcon}}),_vm._v(" "),_c('h3',{staticClass:"mb-4 font-weight-bold text-center"},[_vm._v("\n    "+_vm._s(_vm.$t((_vm.prefix + ".finish_edition." + _vm.statusText + ".title")))+"\n  ")]),_vm._v(" "),(_vm.success)?[(_vm.success)?_c('p',{staticClass:"success-description text-center mb-5 w-100 p-3",domProps:{"innerHTML":_vm._s(
        _vm.$t((_vm.prefix + ".finish_edition.success.description"), {
          title: _vm.media.title,
          date: _vm.date,
          time: _vm.time,
        })
      )}}):_vm._e()]:[_c('p',{staticClass:"text-center mb-5",domProps:{"innerHTML":_vm._s(_vm.$t((_vm.prefix + ".finish_edition.error.description")))}}),_vm._v(" "),_c('p',{staticClass:"error-info border-left border-cherry w-100 p-3 mb-0 font-weight-bold"},[_vm._v("\n      "+_vm._s(_vm.$t((_vm.prefix + ".finish_edition.error.info")))+"\n    ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }