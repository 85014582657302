<template>
  <div class="wrapper flex-1 d-flex align-items-start pt-md-5">
    <hsLoading v-if="isLoading" />
    <template v-else-if="!isLoading && loadedFunnel">
      <div class="container flex-1 d-flex flex-column align-items-center justify-content-center px-3 py-5 py-md-3">
        <h3 class="container-text align-self-start align-self-sm-center mb-3 text-md-center font-weight-bold w-75">
          {{ $t(`${prefix}.title`) }}
        </h3>
        <p class="container-text align-self-start align-self-sm-center description mb-5 text-md-center lh-150 w-75">
          {{ $t(`${prefix}.description`) }}
        </p>
        <div class="bg-white w-100 rounded-sm shadow d-flex align-items-center flex-column p-4 mb-5">
          <p class="mb-4 font-weight-bold text-center">{{ $t(`${prefix}.card.title`) }}</p>
          <div class="d-flex w-50 justify-content-around">
            <hs-button
              @click="copyToClipboard('page-url')"
              variant="link"
              class="d-flex flex-column align-items-center"
            >
              <hs-icon icon="copy" class="text-dark rounded-circle mb-2 copy-icon" size="30" />
              <span class="btn-text font-weight-normal">{{ $t(`${prefix}.card.options.copy`) }}</span>
            </hs-button>
            <hs-button variant="link" :href="facebookUrl" target="_blank" class="d-flex flex-column align-items-center">
              <hs-icon variant="brand" icon="facebook-f" size="30" class="mb-2 facebook-color" />
              <span class="btn-text font-weight-normal">{{ $t(`${prefix}.card.options.facebook`) }}</span>
            </hs-button>
            <hs-button
              variant="link"
              :href="whatsappUrl"
              data-action="share/whatsapp/share"
              target="_blank"
              class="d-flex flex-column align-items-center d-lg-none"
            >
              <hs-icon variant="brand" icon="whatsapp" size="30" class="whatsapp-color mb-2" />
              <span class="btn-text font-weight-normal">
                {{ $t(`${prefix}.card.options.whatsapp`) }}
              </span>
            </hs-button>
          </div>
        </div>
        <input type="hidden" ref="page-url" id="page-url" :value="url" />
        <hs-button :to="{ name: 'FunnelsList' }" variant="primary">
          {{ $t(`${prefix}.button`) }}
        </hs-button>
      </div>
    </template>
  </div>
</template>

<script>
import Clipboard from '@/shared/mixins/Clipboard';
import ToastHelper from '@/shared/helpers/toast';
import { mapState, mapGetters, mapActions } from 'vuex';
import { hsLoading } from '@/components';

export default {
  mixins: [Clipboard],
  props: {
    funnelId: {
      required: true,
    },
  },
  components: { hsLoading },
  computed: {
    ...mapState('funnel', { loadedFunnel: state => state.loadedFunnel }),
    ...mapGetters('funnel', ['getFirstSqueezePage', 'pageUrl']),
    prefix() {
      return 'sparkfunnels.funnels.share';
    },
    url() {
      return this.pageUrl(this.getFirstSqueezePage);
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer.php?u=${this.url}`;
    },
    whatsappUrl() {
      return `whatsapp://send?text=${this.url}`;
    },
  },
  data() {
    return { isLoading: false };
  },
  methods: {
    ...mapActions('funnel', ['loadFunnel']),
    copyToClipboard(ref) {
      try {
        const result = this.clipboardCopy(ref);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t(`${this.prefix}.actions.copy.success`));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefix}.actions.copy.error`));
      }
    },
  },
  created() {
    this.isLoading = true;
    this.loadFunnel(this.funnelId)
      .catch(() => this.$router.push({ name: 'FunnelsList' }))
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: $grey-10;
  background-image: url('~@/assets/images/SparkFunnels/Funnels/Create/paper_plane.svg');
  background-repeat: no-repeat;
  background-position: 145% 10%;
  background-size: 50%;

  @media only screen and (min-width: $screen-bs-sm) {
    background-size: 25%;
    background-position: 100% 10%;
  }

  @media only screen and (min-width: $screen-bs-md) {
    background-size: 15%;
    background-position: 100% 7%;
  }
}

.container {
  max-width: 530px;

  &-text {
    @media only screen and (min-width: $screen-bs-md) {
      width: 100% !important;
    }
  }
}

.description {
  color: $grey-30;
}

.whatsapp-color {
  color: #4fcc5d;
}

.facebook-color {
  color: #3a559f;
}

.btn-text {
  font-size: 10px;
  color: $grey-30;
}

.copy-icon {
  background-color: $light-blue;
}
</style>
