<template>
  <div class="container-funnel-name">
    <hs-placeholder v-if="isLoading" animated width="70%" height="30px" class="mb-2" />
    <hs-editable-text
      v-else
      id="editable-text"
      @save="save"
      @cancel="cancel"
      v-model="loadedFunnel.name"
      :allowSave="!$v.funnelName.$invalid"
      alignment="start"
      :customClass="['h3', 'font-weight-bold', 'lh-125']"
    >
      <div class="position-relative">
        <label for="edit-funnel-name" class="position-absolute mb-0 font-weight-bold custom-label">{{
          $t('sparkfunnels.funnels.edit.funnel_name')
        }}</label>
        <hs-input
          id="edit-funnel-name"
          @blur="$v.funnelName.$touch()"
          v-model="funnelName"
          :state="!$v.funnelName.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.funnelName.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.funnelName.required">{{ $t('sparkfunnels.funnels.edit.validation.required') }}</span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </div>
    </hs-editable-text>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import { mapState, mapActions } from 'vuex';
import hsEditableText from '@/components/EditableText';

export default {
  components: {
    hsEditableText,
  },
  computed: {
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
  },
  data() {
    return {
      funnelName: '',
      isLoading: false,
    };
  },
  validations: {
    funnelName: {
      required,
    },
  },
  methods: {
    ...mapActions('funnel', ['updateLoadedFunnel']),
    save() {
      this.isLoading = true;
      const payload = { ...this.loadedFunnel };
      payload['name'] = this.funnelName;
      this.updateLoadedFunnel(payload)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkfunnels.funnels.edit.toast.updated_funnel_name'));
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.edit.toast.updated_funnel_name_error'));
        })
        .finally(() => (this.isLoading = false));
    },
    cancel() {
      this.funnelName = this.loadedFunnel.name;
    },
  },
  mounted() {
    this.funnelName = this.loadedFunnel ? this.loadedFunnel.name : '';
  },
};
</script>

<style lang="scss" scoped>
/deep/ #edit-funnel-name {
  height: 45px;
}

/deep/ #editable-text {
  #btn-cancel,
  #btn-save {
    height: 100%;
    width: 45px;
  }
}
.container-funnel-name {
  width: 100%;
  @media only screen and (min-width: $screen-bs-md) {
    width: 50%;
  }
}

.custom-label {
  top: -20px;
}
</style>
