<template>
  <b-modal
    :ok-variant="variant"
    :ok-disabled="false"
    :size="size"
    footer-class="tlw-px-4 md:tlw-px-12 tlw-py-2 tlw-my-6 tlw-border-t-0 tlw-flex tlw-flex-col-reverse tlw-justify-center tlw-items-center sm:tlw-flex-row"
    header-class="tlw-pl-4 md:tlw-pl-6 tlw-pt-4 tlw-pb-2 tlw-border-b-0"
    cancel-variant="cancel-confirm-modal"
    body-class="tlw-py-0 tlw-px-4 md:tlw-px-12"
    centered
    v-bind="$attrs"
    v-on="$listeners"
    @hidden="resetForm"
    hide-footer
  >
    <div class="tlw-flex tlw-flex-col tlw-items-center">
      <hs-icon :icon="icon" :variant="iconVariant" class="icon tlw-mt-4 tlw-mb-4" :class="[iconClass]" />
      <h3 class="tlw-mb-4 tlw-font-bold tlw-text-center" v-html="customTitle"></h3>
      <p class="tlw-mx-6 tlw-mb-4 tlw-text-center" v-if="description" v-html="description"></p>
      <div class="tlw-flex tlw-justify-around tlw-w-80">
        <hs-button variant="outline-cherry" @click="$emit('cancel')">
          {{ $t(`${prefix}.delete_confirm_modal.cancel_title`) }}
        </hs-button>
        <hs-button variant="cherry" @click="$emit('confirm')">
          {{ $t(`${prefix}.delete_confirm_modal.ok_title`) }}
        </hs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'ConfirmModalDeleteEmail',
  inheritAttrs: false,
  props: {
    customTitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    verificationText: {
      type: String,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: 'bell',
    },
    iconVariant: {
      type: String,
      default: 'regular',
    },
    placeholder: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'smd',
    },
    prefix: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        text: null,
      },
    };
  },
  validations: {
    form: {
      text: {
        sameAs: sameAs(function() {
          return this.verificationText;
        }),
      },
    },
  },
  methods: {
    resetForm() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },
  },
  computed: {
    iconClass() {
      return `text-${this.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 3rem;
}
</style>
