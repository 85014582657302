<template>
  <div class="pb-4 mb-2 mb-sm-0">
    <div :class="['d-flex mb-3 pb-2 pb-md-0', customClasses]">
      <hs-icon :icon="icon" class="title-icon mr-3" />
      <h6 class="title lh-150 text-uppercase font-weight-bold">{{ title }}</h6>
    </div>
    <div class="ml-md-3 pl-md-1">
      <slot name="container" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    customClasses: {
      type: String,
      default: 'mb-md-4',
    },
  },
};
</script>

<style lang="scss" scoped>
.title-icon {
  color: $grey-32;
}
.title {
  color: $grey-25;
}
</style>
