<template>
  <div>
    <SectionContainer icon="external-link" :title="$t(`${prefix}.title`)">
      <template slot="container">
        <h6 class="font-weight-bold mb-2">
          {{ $t(`${prefix}.share_link`) }}
        </h6>
        <div class="d-flex mb-3">
          <hs-placeholder v-if="isLoading" animated class="flex-1" height="30px" />
          <template v-else>
            <a class="text-primary lh-150 text-break" :href="stagePageUrl" target="_blank">{{ stagePageUrl }}</a>
            <hs-button
              id="copy-page-url"
              variant="link"
              class="text-dark align-self-start pt-0 pr-0"
              @click="copyToClipboard('page-url')"
            >
              <hs-icon class="clone-icon font-weight-light" variant="regular" icon="clone" size="24" />
            </hs-button>
          </template>
        </div>
        <input type="hidden" ref="page-url" id="page-url" :value="stagePageUrl" />
        <div class="d-flex align-items-center mb-2">
          <h6 class="font-weight-bold">
            {{ $t(`${prefix}.custom_slug.input.label`) }}
          </h6>
        </div>
        <div class="d-flex flex-column flex-md-row mb-5 mb-md-0 pb-5 pb-md-0">
          <div class="d-flex flex-column mb-4 mb-md-0 mr-md-3 flex-1">
            <div class="d-flex">
              <div class="domain margin-auto border-right-0 d-flex align-items-center">
                {{ $t(`${prefix}.custom_slug.input.prefix`) }}
              </div>
              <div class="w-100">
                <hs-input
                  class="edit-slug-template"
                  type="text"
                  :placeholder="$t(`${prefix}.custom_slug.input.placeholder`)"
                  @blur="$v.slug.$touch()"
                  v-model="slug"
                />
              </div>
            </div>
            <hs-form-invalid-feedback :state="false" v-if="!$v.slug.required">
              {{ $t(`${prefix}.custom_slug.input.validations.required`) }}
            </hs-form-invalid-feedback>
            <hs-form-invalid-feedback :state="false" v-if="!$v.slug.hasSpace">
              {{ $t(`${prefix}.custom_slug.input.validations.format`) }}
            </hs-form-invalid-feedback>
          </div>
          <hs-button
            id="save-button"
            variant="outline-primary"
            class="align-self-md-start"
            :disabled="$v.$invalid"
            @click="save"
          >
            {{ $t(`${prefix}.save`) }}
          </hs-button>
        </div>
      </template>
    </SectionContainer>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import Clipboard from '@/shared/mixins/Clipboard';
import { mapActions, mapGetters } from 'vuex';
import SectionContainer from './SectionContainer';

export default {
  mixins: [Clipboard],
  components: { SectionContainer },
  props: {
    stage: {
      type: Object,
      required: true,
    },
    funnel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slug: this.stage.slug,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('funnel', ['pageUrl']),
    stagePageUrl() {
      return this.pageUrl(this.stage);
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.page_link';
    },
  },
  validations: {
    slug: {
      required,
      hasSpace: value => !!value && !!value.match(/^[a-zA-Z0-9/-]*$/),
    },
  },
  methods: {
    ...mapActions('funnel', ['updateStageSlug']),
    copyToClipboard(ref) {
      try {
        const result = this.clipboardCopy(ref);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t(`${this.prefix}.actions.copy.success`));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefix}.actions.copy.error`));
      }
    },
    save() {
      this.isLoading = true;
      this.updateStageSlug({ funnelId: this.funnel.id, stageId: this.stage.id, newSlug: this.slug })
        .then(() => ToastHelper.successMessage(this.$t(`${this.prefix}.actions.save.success`)))
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.actions.save.error`)))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.help-btn {
  height: 24px;
  width: 24px;
  background: $purple;
}

.domain {
  font-size: 12px;
  background-color: $grey-10;
  padding: 6px;
  color: $grey-25;
  border: 1px solid $grey-22;
  border-radius: 0.125rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/deep/ .edit-slug-template {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.slug-info {
  color: $grey-30;
  font-size: 12px;
}

.slug-description {
  color: $grey-32;
}

/deep/ .clone-icon {
  @media only screen and (min-width: $screen-bs-md) {
    font-size: 16px !important;
  }
}

#save-button {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
