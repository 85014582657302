
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LinkCheckoutForm extends Vue {
  @Prop({ type: Object }) value!: any;
  @Prop({ type: Object }) formState!: any;

  prefix = 'sparkfunnels.funnels.edit.sections.checkout';

  get checkoutLabel() {
    const {
      prefix,
      value: { type },
    } = this;
    return `(${this.$t(`${prefix}.types.${type}.label`)})`;
  }

  get invalidFormText() {
    const { prefix } = this;
    const { url } = this.formState.form;
    const key = !url.url ? 'wrong_link' : !url.required ? 'required_value' : '';
    return key.length > 0 ? this.$t(`${prefix}.${key}`) : key;
  }

  updateUrl(url: string) {
    this.$emit('update-url', url);
  }
}
