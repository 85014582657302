


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { required, url } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import {
  CheckoutStages,
  CheckoutStage,
  CheckoutStageForm,
  CheckoutStageType,
  Funnel,
  UpdateStageCheckout,
} from '@/types/funnel';
import { hsLoading, hsRadioBtnGroup } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import { analyticsService } from '@/services';
import { CheckoutStages as checkoutStages } from '@/libs/funnel';
import SectionContainer from './SectionContainer.vue';
import LinkCheckoutForm from './Checkouts/LinkCheckoutForm.vue';
import HeroCheckoutForm from './Checkouts/HeroCheckoutForm.vue';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { FUNNEL_CHECKOUT_CONFIGURED } from '@/libs/analytics';
import { BaseProduct, Course, PaymentOption, ProductCourse, ProductLibrary } from '@/types/products';

type Product = BaseProduct & ProductLibrary & ProductCourse;
type CheckoutForm = typeof LinkCheckoutForm | typeof HeroCheckoutForm;

const SchoolModule = namespace('school');
const FunnelModule = namespace('funnel');

@Component({
  components: {
    hsRadioBtnGroup,
    hsLoading,
    SectionContainer,
    LinkCheckoutForm,
    HeroCheckoutForm,
  },

  mixins: [validationMixin],

  validations: {
    form: { url: { url, required } },
  },
})
export default class Checkout extends Vue {
  @Prop({ type: Object, required: true }) stage!: CheckoutStage;

  @Prop({ type: Object, required: true }) funnel!: Funnel;

  @Prop({ type: String, default: 'mb-4' }) customClasses!: string;

  @SchoolModule.Getter getDefaultDomain!: () => string;
  @FunnelModule.Action updateStageCheckout!: (o: UpdateStageCheckout) => Promise<void>;

  prefix = 'sparkfunnels.funnels.edit.sections.checkout';

  isLoading = false;

  showAllCheckoutOptions = false;

  checkoutForm: CheckoutForm = HeroCheckoutForm;

  form: CheckoutStageForm = {
    type: CheckoutStageType.CHECKOUT_HEROSPARK,
    url: '',
    product: null,
    paymentOption: null,
  };

  created() {
    const { url, type } = this.stage;
    const fixedType = type === CheckoutStageType.CHECKOUT ? CheckoutStageType.CHECKOUT_HEROSPARK : type;
    this.showAllCheckoutOptions = this.isTypeCheckoutLink(type);
    this.checkoutForm = this.componentByCheckoutType(type);
    this.form = { ...this.form, type: fixedType, url: url || '' };
  }

  get customStyle() {
    return {
      'max-width': '87px',
      'min-width': '87px',
      flex: '1',
      background: '#FFFFFF',
      'box-shadow': '0px 1px 4px rgba(0, 0, 0, 0.05)',
    };
  }

  get platforms() {
    return [
      {
        image: require(`@/assets/images/checkout/herospark.svg`),
        value: CheckoutStageType.CHECKOUT_HEROSPARK,
      },
      {
        image: require(`@/assets/images/checkout/edools.svg`),
        value: CheckoutStageType.CHECKOUT_EDOOLS,
        hidden: !this.showAllCheckoutOptions,
      },
      {
        image: require(`@/assets/images/checkout/eadbox.svg`),
        value: CheckoutStageType.CHECKOUT_EADBOX,
        hidden: !this.showAllCheckoutOptions,
      },
      {
        image: require(`@/assets/images/checkout/hotmart.svg`),
        value: CheckoutStageType.CHECKOUT_HOTMART,
        hidden: !this.showAllCheckoutOptions,
      },
    ];
  }

  get preserveLink() {
    return this.isTypeCheckoutLink(this.form.type) && Boolean(this.stage.url);
  }

  isTypeCheckoutLink(type: CheckoutStageType) {
    return (checkoutStages as CheckoutStages).gt(type, CheckoutStageType.CHECKOUT_HEROSPARK);
  }

  componentByCheckoutType(type: CheckoutStageType) {
    return this.isTypeCheckoutLink(type) ? LinkCheckoutForm : HeroCheckoutForm;
  }

  changeType(type: CheckoutStageType) {
    this.form = {
      type,
      url: this.preserveLink ? this.stage.url! : '',
      product: null,
      paymentOption: null,
    };

    this.checkoutForm = this.componentByCheckoutType(type);
  }

  onShowAllCheckoutOptions() {
    this.showAllCheckoutOptions = true;
  }

  updateUrl(url: string) {
    this.form.url = url;
  }

  save() {
    const {
      funnel: { id: funnelId },
      stage: { id: stageId },
      prefix,
    } = this;
    this.isLoading = true;

    const requestData = { funnelId, stageId, ...this.form };

    this.updateStageCheckout(requestData)
      .then(() => {
        this.stage.product_id = requestData.product?.id || null;
        this.stage.payment_option_id = requestData.paymentOption?.id || null;
        this.trackConfiguration();
        ToastHelper.successMessage(this.$t(`${prefix}.toast.edited_checkout_url`));
      })
      .catch(() => ToastHelper.dangerMessage(this.$t(`${prefix}.toast.error_edit_checkout`)))
      .finally(() => (this.isLoading = false));
  }

  onSelectedProduct(product: Product) {
    this.form.paymentOption = null;
    this.form.product = product;
  }

  onLoadedProductCourse(course: Course) {
    this.form.product = { ...this.form.product, course } as Product;
  }

  onSelectedPaymentOption(paymentOption: PaymentOption) {
    this.form.paymentOption = paymentOption;
    const url = makeCheckoutUrl(
      this.$FCL.hasSparkCheckout(),
      this.form.paymentOption!,
      this.form.product!,
      this.getDefaultDomain
    );
    this.form.url = url;
  }

  trackConfiguration() {
    analyticsService.track({
      event: FUNNEL_CHECKOUT_CONFIGURED,
      props: {
        funnel_checkout_type: this.form.type,
      },
      context: undefined,
      callback: undefined,
    });
  }
}
