<template>
  <div>
    <b-modal
      :id="modalId"
      hide-footer
      :title="$t(`${prefix}.modal_title`)"
      centered
      body-class="px-4 pb-4"
      @close="hide"
      @hidden="reset"
    >
      <hs-loading v-if="isLoading" />
      <b-tabs v-else v-model="tabIndex" nav-class="d-none" nav-wrapper-class="d-none">
        <b-tab v-for="(tab, index) in tabs" :key="index" :disabled="tab.disabled">
          <component
            v-bind:is="tab.component"
            :media="mediaInEditing"
            :success="success"
            @change="changeMedia"
          ></component>
          <div class="mt-5 w-100 d-flex justify-content-center flex-column-reverse flex-md-row">
            <hs-button class="edit-btn" @click="tab.cancelMethod" variant="link">{{ tab.cancelTitle }}</hs-button>
            <hs-button
              @click="tab.okMethod"
              :variant="tab.okVariant"
              class="edit-btn mb-3 mb-md-0 ml-md-2"
              :disabled="tab.okDisabled"
              >{{ tab.okTitle }}</hs-button
            >
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import ConfirmEdition from './ConfirmEdition';
import MediaConfig from './MediaConfig';
import ThirdPartyConfig from './ThirdPartyConfig';
import FinishEdition from './FinishEdition';
import { required } from 'vuelidate/lib/validators';
import datesHelper from '@/shared/helpers/dates';
import { mapActions } from 'vuex';
import { hsLoading } from '@/components';
import { analyticsService } from '@/services';
import dayjs from 'dayjs';

export default {
  name: 'FunnelsWebinarSectionEdit',
  components: {
    ConfirmEdition,
    MediaConfig,
    ThirdPartyConfig,
    FinishEdition,
    hsLoading,
  },
  computed: {
    tabs() {
      return [
        {
          component: ConfirmEdition,
          okMethod: this.proceed,
          cancelMethod: this.hide,
          okTitle: this.$t(`${this.prefix}.confirm_edition.ok_title`),
          cancelTitle: this.$t(`${this.prefix}.confirm_edition.cancel_title`),
          disabled: this.isFirstTimeEditing,
          okDisabled: false,
          okVariant: 'primary',
        },
        {
          component: ThirdPartyConfig,
          okMethod: this.proceed,
          cancelMethod: this.recede,
          okTitle: this.$t(`${this.prefix}.third_party_config.ok_title`),
          cancelTitle: this.$t(`${this.prefix}.third_party_config.cancel_title`),
          disabled: false,
          okDisabled: false,
          okVariant: 'primary',
        },
        {
          component: MediaConfig,
          okMethod: this.edit,
          cancelMethod: this.recede,
          okTitle: this.$t(`${this.prefix}.media_config.ok_title`),
          cancelTitle: this.$t(`${this.prefix}.media_config.cancel_title`),
          disabled: false,
          okDisabled: this.$v.mediaInEditing.title.$invalid || this.$v.mediaInEditing.start_at.$invalid,
          okVariant: 'primary',
        },
        {
          component: FinishEdition,
          okMethod: this.success ? this.hide : this.recede,
          cancelMethod: this.success ? this.recede : this.hide,
          okTitle: this.$t(`${this.prefix}.finish_edition.${this.statusText}.ok_title`),
          cancelTitle: this.$t(`${this.prefix}.finish_edition.${this.statusText}.cancel_title`),
          disabled: false,
          okDisabled: false,
          okVariant: this.success ? 'primary' : 'secondary',
        },
      ];
    },
    statusText() {
      return this.success ? 'success' : 'error';
    },
    modalId() {
      return 'webinar-edit';
    },
    isFirstTimeEditing() {
      return (
        this.media.title === this.$t(`sparkfunnels.funnels.stages.types.Funnel::WebinarStage`) &&
        this.media.start_at === null
      );
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.edit';
    },
  },
  data() {
    return {
      tabIndex: 0,
      success: false,
      isLoading: false,
      mediaInEditing: {
        ...this.media,
        start_at: this.media.start_at ? dayjs(this.media.start_at).toDate() : new Date(),
      },
    };
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('funnel', ['updateMedia']),
    recede() {
      this.tabIndex--;
    },
    proceed() {
      this.tabIndex++;
    },
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    reset() {
      this.tabIndex = 0;
      this.mediaInEditing = { ...this.media, start_at: this.media.start_at ? this.media.start_at : new Date() };
      this.success = false;
    },
    changeMedia(newMedia) {
      this.mediaInEditing = { ...this.mediaInEditing, ...newMedia };
    },
    edit() {
      this.isLoading = true;
      const media = {
        type: this.mediaInEditing.type,
        title: this.mediaInEditing.title,
        start_at: this.mediaInEditing.start_at,
        text: this.mediaInEditing.text,
      };
      this.updateMedia({
        mediaId: this.stage.media_id,
        media,
      })
        .then(() => (this.success = true))
        .catch(() => (this.success = false))
        .finally(() => {
          this.isLoading = false;
          this.proceed();
        });
    },
  },
  validations: {
    mediaInEditing: {
      title: { required },
      start_at: { mustBeFuture: value => (value ? datesHelper.isFutureDate(value) : true), required },
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-btn {
  min-height: 45px;

  @media only screen and (min-width: $screen-bs-md) {
    min-height: auto !important;
  }
}
</style>
