<template>
  <div>
    <SectionContainer icon="desktop" :title="$t(`${prefix}.title`)">
      <hs-loading v-if="isLoading" />
      <template v-else slot="container">
        <div class="position-relative">
          <div class="custom-iframe position-relative" :style="customStyle">
            <template v-if="stage.isPublished">
              <iframe :src="`${pageUrl(stage)}?preview=true`" class="w-100 h-100 border-0" scrolling="no" />
            </template>
            <div class="fix-div w-100 h-100 position-absolute" />
          </div>
          <EditOptions :stage="stage" />
        </div>
      </template>
    </SectionContainer>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import BrowserHelper from '@/shared/helpers/browser';
import { pagesService } from '@/services';
import { hsLoading } from '@/components';
import SectionContainer from '../SectionContainer';
import EditOptions from './EditOptions';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    EditOptions,
    hsLoading,
    SectionContainer,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('funnel', ['pageUrl']),
    ...mapState('jwt', {
      jwt: state => state.encoded,
    }),
    customStyle() {
      return this.stage.isPublished
        ? {}
        : { 'background-size': 'cover', 'background-image': `url(${this.pagesPreview})` };
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.page_config';
    },
    pagesPreview() {
      if (this.theme) {
        if (this.theme.previews) {
          if (BrowserHelper.isTablet()) return this.theme.previews.tablet || '';
          if (BrowserHelper.isMobile()) return this.theme.previews.mobile || '';
          return this.theme.previews.desktop || '';
        }
        return this.theme.preview || '';
      }
      return '';
    },
  },
  data() {
    return {
      isLoading: false,
      theme: null,
    };
  },
  methods: {
    ...mapActions('jwt', ['load']),
    ...mapGetters('jwt', ['isLoaded']),
    loadJwtToken() {
      if (!this.isLoaded()) {
        this.load();
      }
    },
    loadAssetsTheme() {
      this.isLoading = true;
      pagesService
        .getThemeData({
          jwt: this.jwt,
          themeId: this.stage.theme_id || this.stage.page_theme_id,
        })
        .then(res => (this.theme = res))
        .catch(() => ToastHelper.dangerMessage(`${this.prefix}.fetch_theme_assets_error`))
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.loadJwtToken();
    this.loadAssetsTheme();
  },
};
</script>

<style lang="scss" scoped>
$default-height: 287px;

.fix-image-height {
  min-height: $default-height;
  max-height: $default-height;
}

.custom-iframe {
  height: $default-height;
  .fix-div {
    top: 0;
    background: rgba($grey, 0);
    @media only screen and (min-width: $screen-bs-md) {
      background: rgba($grey, 0.8);
    }
  }
}
</style>
