<template>
  <div class="d-flex justify-content-between justify-content-md-end align-items-center">
    <span>{{ $t(`sparkfunnels.funnels.edit.funnel_status.${loadedFunnel.status}`) }}</span>
    <hs-toggle class="custom-toggle ml-1 pl-2" @change="toggle" v-model="status" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';

export default {
  computed: {
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
  },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    ...mapActions('funnel', ['updateLoadedFunnelStatus']),
    toggle(newValue) {
      const newStatus = newValue ? 'active' : 'unactive';
      this.updateLoadedFunnelStatus({ funnel: this.loadedFunnel, newStatus })
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkfunnels.funnels.edit.funnel_status.toast.update_status_success'));
        })
        .catch(() => {
          this.status = !newValue;
          ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.edit.funnel_status.toast.update_status_error'));
        });
    },
  },
  created() {
    this.status = this.loadedFunnel.status === 'active';
  },
};
</script>

<style lang="scss" scoped>
.custom-toggle {
  height: 18px;
}
</style>
