<template>
  <component v-if="alreadyOpened" v-bind:is="correctComponent" :funnel="funnel" :stage="stage" />
  <div v-else class="pb-4">
    <hsLoading />
  </div>
</template>

<script>
import CheckoutStage from './CheckoutStage';
import WebinarStage from './WebinarStage';
import PageStage from './PageStage';
import { hsLoading } from '@/components';
import { Stages, CheckoutStages } from '@/libs/funnel';

export default {
  components: { hsLoading },
  props: {
    alreadyOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    correctComponent() {
      if (CheckoutStages.is(this.stage.type)) {
        return CheckoutStage;
      } else if (this.stage.type === Stages.WEBINAR) {
        return WebinarStage;
      } else {
        return PageStage;
      }
    },
  },
};
</script>
