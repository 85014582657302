















































































import debug from 'debug';
import { currency } from '@/shared/helpers/general';
import { hsLoading } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import { CheckoutStageType, CheckoutStage, CheckoutStageForm } from '@/types/funnel';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Tracking from '@/shared/helpers/tracking';
import debounce from 'lodash.debounce';
import {
  APIExtraAttributes,
  ProductLibrary,
  ProductId,
  BaseProduct,
  ProductCourse,
  ProductPaginatedResponse,
  PaymentOption,
  PaymentOptionId,
  Course,
  ResourceId,
} from "@/types/products";

type Product = BaseProduct & ProductLibrary & ProductCourse;

const { PRODUCT_CREATION_INITIATED } = Tracking.Events;
const PRODUCT_COMBO_TYPE = 'path';

const badgeVariants: Record<string, string> = {
  quiz: 'outline-purple',
  webinar: 'outline-danger',
  ebook: 'outline-primary',
  file: 'outline-primary',
  online_course: 'outline-info',
};

const ProductModule = namespace('product');
const CourseModule = namespace('course');

const logging = debug('hs:herospark_checkout');

@Component({
  components: {
    hsLoading,
  },
})
export default class HeroCheckoutForm extends Vue {
  @Prop({}) stage!: CheckoutStage;
  @Prop({}) value!: CheckoutStageForm;
  @Prop({}) formState!: object;

  @ProductModule.Action getAllProducts!: (config: {
    params: APIExtraAttributes
  }) => Promise<ProductPaginatedResponse<Product>>;

  @ProductModule.Action getProductPaymentOptions!: (config: {
    id: PaymentOptionId
  }) => Promise<PaymentOption[]>;

  @CourseModule.Action getCourse!: (resourceId: ResourceId) => Promise<Course>;

  prefix = 'sparkfunnels.funnels.edit.sections.checkout.herosparkcheckoutstage';

  isLoadingProducts = false;
  isLoadingProductDetails = false;
  isSearching: Boolean = false;
  search: string = '';

  products: Product[] | null = null;
  product: Product | null = null;

  paymentOptions: PaymentOption[] | null = null;
  paymentOption: PaymentOption | null = null;

  mounted() {
    const findStageProduct = this.stage.url ?
      (_: Product[]) => this.loadProductAndPaymentOption(this.stage) :
      ((_: Product[]) => {});
    this.isLoadingProducts = true;
    this.fetchProducts()
      .then(findStageProduct)
      .catch(error => console.log(error)); // eslint-disable-line no-console
    this.searchProduct = debounce(this.searchProduct, 500);
  }

  get availableProducts() {
    return this.products || [];
  }

  get hasProducts() {
    return this.availableProducts.length > 0;
  }

  get onlyDefaultPaymentOption() {
    return this.paymentOptions!.length === 1;
  }

  get isSingleOption() {
    return this.onlyDefaultPaymentOption;
  }

  get selectedPaymentOption() {
    return this.onlyDefaultPaymentOption ? this.paymentOptions![0].id : this.paymentOption?.id;
  }

  get canShowPaymentOptions(): boolean {
    const { value: { product }, paymentOptions, isLoadingProductDetails } = this;
    return !isLoadingProductDetails && Boolean(product) && Boolean(paymentOptions);
  }

  get paymentOptionPlaceholder() {
    return this.$t(`${this.prefix}.choose_offer.placeholder`);
  }

  get productPlaceholder() {
    return this.$t(`${this.prefix}.select_product_placeholder`);
  }

  goToCreateProduct(): void {
    Tracking.trackProductCreationInitiated('funnels');
    this.$router.push({ name: 'ProductCreator', query: { location: 'funnels', event: PRODUCT_CREATION_INITIATED } });
  }

  getBadgeVariant(status: string): string {
    return badgeVariants[status];
  }

  paymentOptionLabel(option: PaymentOption, product: Product): string {
    const title = !option.default ? option.title || product.title : 'Oferta padrão';
    return `${title} - ${currency(option.price / 100, true)}`;
  }

  loadProductAndPaymentOption(stage: CheckoutStage): Promise<void> {
    const { product_id, payment_option_id } = stage;
    if (this.stage.type === CheckoutStageType.CHECKOUT_HEROSPARK && Boolean(product_id) && Boolean(payment_option_id)) {
      logging('recovering product %d and offer %d', product_id, payment_option_id)
      return this.onSelectedProductById(product_id!).then(() => {
        return this.onSelectedPaymentOptionById(payment_option_id!);
     });
    }
    return Promise.resolve();
  }

  loadProductCourse(): Promise<Product> {
    const { getCourse } = this;
    return getCourse((this.value.product! as Product).library_resource!.resource.id).then(
      (course: Course) => {
        logging('loaded product course', course);
        this.$emit('loaded-course', course);
        return this.value.product as Product;
      }
    );
  }

  onSelectedProductById(productId: ProductId) : Promise<Product | void> {
    logging("selected product by id %d", productId);
    const product = this.products!.find(({ id }) => id === productId);
    return this.onSelectedProduct(product!);
  }

  onSelectedProduct(product: Product) : Promise<Product | void> {
    logging("selected product", product);
    const { loadProductCourse } = this;
    this.paymentOptions = null;
    this.$emit('selected-product', product);
    this.isLoadingProductDetails = true;

    return this.getPaymentOptionsOfProduct(product.id)
      .then(() => {
          if ((this.value.product! as Product).library_resource!.resource.type == PRODUCT_COMBO_TYPE) {
            const course = { kind: 'path'};
            this.value.product = { ...this.value.product, course } as Product;
            this.isLoadingProductDetails = false;
          } else {
            loadProductCourse().catch(
              error => {
                ToastHelper.dangerMessage(`${this.prefix}.fail_to_load_product_details`);
                return Promise.reject(error);
              }
            ).finally(() => {
              this.isLoadingProductDetails = false;
            });
          }
      });

  }

  getPaymentOptionsOfProduct(product_id){
    return this.getProductPaymentOptions({ id: product_id })
      .then((options: PaymentOption[]): void => {
        this.paymentOptions = options;
        if (options.length === 1) {
           this.onSelectedPaymentOption(options[0]);
        }
      })
      .catch(
        error => {
          ToastHelper.dangerMessage(`${this.prefix}.no_offers_to_list`);
          return Promise.reject(error);
        }
      )
  }

  onSelectedPaymentOptionById(paymentOptionId: PaymentOptionId) {
    logging("selected payment option by id %d", paymentOptionId);
    const paymentOption = this.paymentOptions!.find(({ id }) => id === paymentOptionId)! ;
    return this.onSelectedPaymentOption(paymentOption);
  }

  onSelectedPaymentOption(paymentOption: PaymentOption): void {
    logging("selected payment option", paymentOption);
    this.$emit('selected-payment-option', paymentOption);
  }

  searchProduct(search: string) {
    return this.fetchProducts(search)
  }

  async fetchProducts(search?: string) {
    try {
      const params: APIExtraAttributes = [{ key: 'request_attributes', value: 'library_resource' }];

      if (search?.length) {
        params.push({ key: 'title', value: search });
        this.isSearching = true;
      }

      const { school_products } =  await this.getAllProducts({ params });
      this.products = school_products || [];
      return this.products;
    }finally {
      this.isSearching = false;
      this.isLoadingProducts = false;
    }
  }

  @Watch('search')
    async onChangeSearch() {
      this.isSearching = true;
      this.products = [];
      await this.searchProduct(this.search);
    };
}
