<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold text-center mb-5">
      {{ $t(`${prefix}.media_config.title`) }}
    </h4>
    <div class="mb-4">
      <hs-group :label="$t(`${prefix}.media_config.form.title.label`)" label-for="media-title-input" class="mb-3 pb-3">
        <hs-input
          id="media-title-input"
          @blur="$v.title.$touch()"
          :placeholder="$t(`${prefix}.media_config.form.title.placeholder`)"
          v-model="title"
          :state="!$v.title.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.title.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.title.required">{{ $t(`${prefix}.media_config.form.title.validations.required`) }}</span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </hs-group>
      <div class="datetime d-flex w-100">
        <hs-date-time v-model="date" :min="new Date()">
          <label slot="date-label">{{ $t(`${prefix}.media_config.form.date.label`) }}</label>
          <label slot="time-label">{{ $t(`${prefix}.media_config.form.time.label`) }}</label>
        </hs-date-time>
      </div>
      <hs-form-invalid-feedback :state="false" v-if="$v.media.start_at.$invalid">
        <span v-if="!$v.media.start_at.required" class="mb-3">{{
          $t(`${prefix}.media_config.form.datetime.validations.required`)
        }}</span>
        <span v-else-if="!$v.media.start_at.mustBeFuture">{{
          $t(`${prefix}.media_config.form.datetime.validations.must_be_future`)
        }}</span>
      </hs-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import datesHelper from '@/shared/helpers/dates';

export default {
  components: {
    HsDateTime: () => import('@/components/DateTime'),
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date: {
      get: function() {
        return this.media.start_at;
      },
      set: function(newDate) {
        this.$emit('change', { start_at: newDate });
      },
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.edit';
    },
    title: {
      get: function() {
        return this.media.title;
      },
      set: function(newTitle) {
        this.$emit('change', { title: newTitle });
        this.value = newTitle;
      },
    },
  },
  validations: {
    media: {
      start_at: { mustBeFuture: value => (value ? datesHelper.isFutureDate(value) : true), required },
    },
    title: { required },
    date: { required },
  },
};
</script>

<style lang="scss" scoped>
/deep/ label:not(.form-control) {
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (min-width: $screen-bs-md) {
  .datetime {
    width: 75% !important;
  }
}
</style>
