<template>
  <div class="publish-container d-flex align-items-center" :class="{ 'text-danger': !published }">
    <hs-icon v-if="published" class="pr-2" variant="light" icon="check-circle" />
    <span class="lh-150 font-weight-normal">
      {{ $t(`sparkfunnels.funnels.edit.stage_page.${published ? 'published' : 'unpublished'}`) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    published: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-container {
  color: $green-dark;
}
</style>
