<template>
  <div class="px-3 px-md-4">
    <Webinar :stage="stage" :funnel="funnel" />
    <EmailTemplates :stage="stage" :funnel="funnel" />
  </div>
</template>

<script>
import EmailTemplates from '../sections/EmailTemplates';
import Webinar from '../sections/Webinar';

export default {
  components: {
    Webinar,
    EmailTemplates,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
};
</script>
