<template>
  <div class="d-flex flex-column-reverse flex-md-column">
    <div class="options d-flex flex-column align-items-md-center justify-content-md-center">
      <hs-button id="edit-page" variant="primary" class="mb-3" @click="editPage" :disabled="isLoadingStage">
        <b-spinner small type="grow" v-if="isLoadingStage" />
        {{ $t(`${prefix}.buttons.edit_page`) }}
      </hs-button>
      <hs-button
        id="view-page"
        :class="customClasses"
        variant="outline-secondary"
        @click="viewPage"
        :disabled="isLoadingStage"
      >
        <b-spinner small type="grow" v-if="isLoadingStage" />
        {{ $t(`${prefix}.buttons.view_page`) }}
      </hs-button>
    </div>
    <div>
      <span
        v-if="!stage.isPublished"
        class="d-block preview-message font-weight-bold text-center text-dark px-md-3 pt-3 pt-md-2 pb-2 pb-md-0 lh-150"
      >
        {{ $t(`${prefix}.preview_message`) }}
      </span>
      <h6
        id="edit-page-pixel"
        class="d-flex justify-content-center text-center flex-wrap mb-4 mb-md-0 pt-4"
        @click="setPagesPixel"
        v-html="$t(`${prefix}.pixel_link`)"
      />
    </div>
  </div>
</template>

<script>
import BrowserHelper from '@/shared/helpers/browser';
import ToastHelper from '@/shared/helpers/toast';
import { analyticsService, funnelApi } from '@/services';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    stage: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('funnel', ['pageUrl']),
    ...mapState('jwt', {
      jwt: state => state.encoded,
    }),
    customClasses() {
      return !BrowserHelper.isMobile() ? 'bg-transparent text-white border-white' : '';
    },
    returnUrl() {
      const { origin, pathname } = window.location;
      return encodeURIComponent(`${origin}${pathname}`);
    },
    editUrl() {
      return `${process.env.VUE_APP_PAGES_BASE_URL}/editor/${this.stage.page_id}?token=${this.jwt}&returnUrl=${
        this.returnUrl
      }`;
    },
    editUrlPixel() {
      return `${this.editUrl}&pixel=true`;
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.page_config';
    },
    urlPage() {
      return this.pageUrl(this.stage);
    },
  },
  data() {
    return { isLoadingStage: false, loadedPageId: null };
  },
  methods: {
    editPage() {
      if (BrowserHelper.isMobile()) {
        this.showDesktopModal();
        return;
      }
      this.trackEditPage();
      if (this.stage.page_id || this.loadedPageId) {
        window.open(this.editUrl, '_blank');
        return;
      }

      this.loadStageData('edit_page.page_under_construction', 'edit_page.error_load_page_id', 'editUrl');
    },
    loadStageData(warningMessage, dangerMessage, url) {
      this.isLoadingStage = true;
      funnelApi
        .getStage(this.stage.funnel_id, this.stage.id)
        .then(({ data }) => {
          this.loadedPageId = data.page_id;
          if (this.loadedPageId) window.open(this[url], '_blank');
          else ToastHelper.warningMessage(this.$t(`${this.prefix}.${warningMessage}`));
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.${dangerMessage}`)))
        .finally(() => (this.isLoadingStage = false));
    },
    setPagesPixel() {
      if (BrowserHelper.isMobile()) {
        this.showDesktopModal();
        return;
      }
      this.trackEditPage(true);
      if (this.stage.page_id || this.loadedPageId) {
        window.open(this.editUrlPixel, '_blank');
        return;
      }

      this.loadStageData('edit_page.page_under_construction', 'edit_page.error_load_page_id', 'editUrlPixel');
    },
    showDesktopModal() {
      const title = this.$createElement('h3', {
        class: ['text-center', 'font-weight-bold', 'mb-3'],
        domProps: { innerHTML: this.$t(`${this.prefix}.desktop_modal.title`) },
      });
      const image = this.$createElement('b-img', {
        class: ['mb-3'],
        props: {
          src: require('@/assets/images/SparkFunnels/Funnels/Create/video_edit.svg'),
          center: true,
          fluid: true,
        },
      });
      const description = this.$createElement('p', {
        style: { color: '#797979' },
        class: ['text-center', 'mb-0'],
        domProps: {
          innerHTML: this.$t(`${this.prefix}.desktop_modal.description`),
        },
      });
      this.$bvModal.msgBoxOk([title, image, description], {
        hideHeader: false,
        hideHeaderClose: false,
        headerClass: 'border-0',
        bodyClass: 'px-3 py-0',
        footerClass: 'desktop-modal-footer border-0 d-flex align-items-center justify-content-center py-5',
        okTitle: this.$t(`${this.prefix}.desktop_modal.ok_button`),
        centered: true,
      });
    },
    trackEditPage(pixel = false) {
      analyticsService.track({
        event: 'Page Editing Button Clicked',
        props: {
          funnel_id: this.stage.funnel_id,
          funnel_stage_type: this.stage.type,
          page_id: this.stage.page_id || this.loadedPageId,
          page_published: this.isPagePublished,
          pixel,
        },
      });
    },
    viewPage() {
      if (this.stage.page_id || this.loadedPageId) {
        window.open(this.urlPage, '_blank');
        return;
      }
      this.loadStageData('view_page.page_under_construction', 'view_page.error_load_page_id', 'urlPage');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .pixel-link {
  cursor: pointer;
  display: flex;
  padding-left: 0.25rem;
}

.preview-message {
  font-size: 12px;
  letter-spacing: -0.2px;
}

.options {
  @media only screen and (min-width: $screen-bs-md) {
    position: absolute;
    top: 103.5px;
    width: 100%;
  }
}
</style>
