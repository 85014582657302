<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <hs-icon :icon="statusIcon" class="icon mb-2" :class="{ [`text-${statusVariant}`]: true }" />
    <h3 class="mb-4 font-weight-bold text-center">
      {{ $t(`${prefix}.finish_edition.${statusText}.title`) }}
    </h3>
    <template v-if="success">
      <p
        v-if="success"
        class="success-description text-center mb-5 w-100 p-3"
        v-html="
          $t(`${prefix}.finish_edition.success.description`, {
            title: media.title,
            date,
            time,
          })
        "
      ></p>
    </template>
    <template v-else>
      <p class="text-center mb-5" v-html="$t(`${prefix}.finish_edition.error.description`)"></p>
      <p class="error-info border-left border-cherry w-100 p-3 mb-0 font-weight-bold">
        {{ $t(`${prefix}.finish_edition.error.info`) }}
      </p>
    </template>
  </div>
</template>

<script>
import datesHelper from '@/shared/helpers/dates';

export default {
  props: {
    media: {
      type: Object,
      required: true,
    },
    success: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    date() {
      return datesHelper.format({ date: this.media.start_at, format: this.$t('date.format') });
    },
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.webinar.edit';
    },
    time() {
      return datesHelper.format({ date: this.media.start_at, format: 'HH:mm' });
    },
    statusIcon() {
      return this.success ? 'check-circle' : 'times-circle';
    },
    statusText() {
      return this.success ? 'success' : 'error';
    },
    statusVariant() {
      return this.success ? 'primary' : 'cherry';
    },
  },
};
</script>

<style lang="scss" scoped>
.success-description {
  background: $pale-cyan;
}

.icon {
  font-size: 3rem;
}

.error-info {
  border-width: 2px !important;
  background: #ffd6de;
  font-size: 14px !important;
  color: #e42b2b;
}
</style>
