<template>
  <section class="funnels pt-4 px-5">
    <hsLoading v-if="isLoading" />
    <template v-else-if="!isLoading && funnel">
      <div class="funnel-analytics">
        <div class="row no-gutters">
          <div class="col">
            <div class="mb-5 d-inline-block">
              <h4 data-testid="funnel-name" class="font-weight-bold pb-2">{{ funnel.name }}</h4>
            </div>
            <hs-alert v-if="!loadedFunnelHasLeads" class="mb-4" variant="primary" :show="true" :fade="true" dismissible>
              <span v-html="$t('sparkfunnels.analytics.no_data')"></span>
            </hs-alert>
            <AnalyticsCards class="mb-5" :funnel="funnel" />
            <Graph class="mb-5" :funnel="funnel" :hasValue="loadedFunnelHasLeads" />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { hsLoading } from '@/components';
import AnalyticsCards from './components/AnalyticsCards';

export default {
  name: 'funnel-analytics',
  components: {
    AnalyticsCards,
    Graph: () => import('./components/Graph'),
    hsLoading,
  },
  props: {
    funnelId: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      funnel: null,
    };
  },
  methods: {
    ...mapActions('funnel', ['getFunnel']),
  },
  computed: {
    ...mapGetters('funnel', ['loadedFunnelHasLeads']),
  },
  created() {
    this.isLoading = true;
    this.getFunnel(this.funnelId)
      .then(({ data }) => (this.funnel = data))
      .catch(() => this.$router.push({ name: 'FunnelsList' }))
      .finally(() => (this.isLoading = false));
  },
};
</script>
