<template>
  <div class="w-100 px-3">
    <PageConfiguration :stage="stage" />
    <EmailTemplates :stage="stage" :funnel="funnel" v-if="isDirectOfferFunnel" />
    <PageLink :stage="stage" :funnel="funnel" />
  </div>
</template>

<script>
import EmailTemplates from '../sections/EmailTemplates';
import PageLink from '../sections/PageLink';
import PageConfiguration from '../sections/PageConfiguration';

export default {
  components: {
    EmailTemplates,
    PageLink,
    PageConfiguration,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDirectOfferFunnel() {
      return this.funnel.name !== 'Funil de oferta direta';
    },
  },
};
</script>
