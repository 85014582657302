<template>
  <div class="p-4 position-relative d-table rounded custom-container">
    <div class="sub-container">
      <h5 class="title text-dark font-weight-bold pb-2 lh-150">
        {{ $t('sparkfunnels.funnels.edit.publishing_card.title') }}
      </h5>
      <p class="description lh-150 mb-5 pt-1">
        {{ $t('sparkfunnels.funnels.edit.publishing_card.description') }}
      </p>
      <hs-placeholder v-if="isLoading" class="custom-placeholder" animated width="100%" height="100%" />
      <hs-button
        v-else
        class="custom-button align-items-center"
        variant="primary"
        @click="
          () => {
            trackInformation();
            publish();
          }
        "
      >
        {{ $t('sparkfunnels.funnels.edit.publishing_card.button') }}
      </hs-button>
    </div>
    <b-img-lazy
      class="cropped-img-right position-absolute"
      :src="require('@/assets/images/funnel/new_funnel_card_right.svg')"
      alt="Flying paper plane"
    />
    <Modal id="email-edited-modal" :hide-header="true" size="modal-email-not-edited">
      <div class="email-edited-modal">
        <img src="@/assets/images/funnel/virtualpapers_onlypapersgrey.svg" />
        <h4 v-html="$t('sparkfunnels.funnels.edit.publishing_card.modal_not_edited_email.title')"></h4>
        <p v-html="$t('sparkfunnels.funnels.edit.publishing_card.modal_not_edited_email.description')"></p>
        <hs-button variant="cherry" @click="$bvModal.hide('email-edited-modal')">
          {{ $t('sparkfunnels.funnels.edit.publishing_card.modal_not_edited_email.buttons.back_edit_email') }}
        </hs-button>
        <hs-button variant="link" @click="ignoreEmailsAction">
          {{ $t('sparkfunnels.funnels.edit.publishing_card.modal_not_edited_email.buttons.next_not_edit_email') }}
        </hs-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import Modal from '@/components/Modal';
import analyticsService from '@/services/analytics';
import pagesService from '@/sparkfunnels/services/pages';

export default {
  name: 'PublishCard',
  components: { Modal },
  computed: {
    ...mapGetters('funnel', ['getStagesEmails']),
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
    ...mapState('jwt', {
      jwt: state => state.encoded,
    }),
  },
  created() {
    this.ignoreEmails = false;
  },
  data() {
    return {
      isLoading: false,
      ignoreEmails: false,
      allEditedEmails: false,
    };
  },
  methods: {
    ...mapActions('funnel', ['updateStatus']),
    publish() {
      this.ignoreEmails ? this.next() : this.checkEmails();
    },
    checkEmails() {
      let hasAtLeastOneEditedEmail = this.getStagesEmails.some(stage => stage.emails.some(email => !email.edited));
      !hasAtLeastOneEditedEmail ? this.next() : this.isModalAlertEmails();
    },
    next() {
      const pages = this.loadedFunnel.stages.reduce(
        (acc, stage) => (stage.page_id && acc.push(stage.page_id), acc),
        []
      );
      if (!pages.length) {
        this.routeToShareFunnel();
        return;
      }
      this.isLoading = true;
      pagesService
        .publishPages({ jwt: this.jwt, pages })
        .then(() => this.updateStatus({ funnelId: this.loadedFunnel.id, newStatus: 'active' }))
        .then(() => this.routeToShareFunnel())
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.edit.publishing_card.publishing_error')))
        .finally(() => (this.isLoading = false));
    },
    isModalAlertEmails() {
      this.$bvModal.show('email-edited-modal');
    },
    routeToShareFunnel() {
      this.$router.push({ name: 'ShareFunnel', params: { funnelId: this.loadedFunnel.id } });
    },
    trackInformation() {
      analyticsService.track({
        event: 'Funnels published click',
        props: {
          funnel_model_type: this.loadedFunnel.template_type,
          funnel_model_name: this.loadedFunnel.template_name,
          funnel_id: this.loadedFunnel.id,
          from: 'edit',
        },
      });
    },
    ignoreEmailsAction() {
      this.ignoreEmails = true;
      this.publish();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  width: 100%;
  @media only screen and (min-width: $screen-bs-md) {
    width: auto;
  }
}
.custom-container {
  background: $white;
  border-radius: 4px 4px 0px 0px;

  @media only screen and (min-width: $screen-bs-sm) {
    background: $light-blue;
  }

  .sub-container {
    width: 100%;
    @media only screen and (min-width: $screen-bs-sm) {
      width: 75%;
    }
  }

  .title {
    width: 75%;
    @media only screen and (min-width: $screen-bs-sm) {
      width: 100%;
      text-align: left;
    }
  }

  .description {
    color: $grey-32;
  }
}

.cropped-img-right {
  right: 0;
  height: 20%;
  top: -0.5rem;

  @media only screen and (min-width: $screen-bs-sm) {
    height: 50%;
    top: 1.2rem;
  }

  @media only screen and (min-width: $screen-bs-md) {
    height: 55%;
  }
}

.custom-placeholder {
  height: 45px;
  @media only screen and (min-width: $screen-bs-md) {
    width: 50%;
    height: 36px;
  }
}

.modal-dialog.modal-modal-email-not-edited.modal-dialog-centered.modal-dialog-scrollable {
  width: 571px;
  height: 530px;
  padding: 40px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
}

.email-edited-modal {
  height: 480px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  font-family: Mulish, sans-serif;

  img {
    width: 92px;
    height: 132px;
    display: block;
    margin: 0 auto 0 auto;
  }

  h4 {
    margin: 16px auto 24px auto;
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 115%;
    text-align: center;
    color: $cherry;
  }

  p {
    width: 491px;
    text-align: center;
    color: $grey;
    margin-bottom: 0;
  }
}
</style>
