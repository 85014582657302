<template>
  <div class="d-flex flex-column">
    <SectionContainer icon="envelope" :title="$t(`${prefix}.title`)" :custom-classes="customClasses">
      <template slot="container">
        <hs-loading v-if="isLoading" />
        <div v-else>
          <h5
            v-if="!emails.length"
            class="without-email lh-150 mb-3"
            v-html="
              $t(`${this.prefix}.whitout_email`, {
                stage: stage.name,
              })
            "
          />
          <div v-else>
            <div
              class="email-template d-flex flex-column"
              v-for="(emailTemplate, index) in emails"
              :key="`email-template-${index}`"
            >
              <MessageEmailNotEdited :show="!emailTemplate.edited" />
              <div class="d-flex align-items-center">
                <span
                  class="edit-subject lh-150 flex-1 d-flex flex-row text-truncate text-dark font-weight-bold"
                  @click="showEditEmailModal(emailTemplate)"
                >
                  <span
                    class="d-none d-md-block font-weight-normal text-truncate w-75"
                    v-html="$t(`${prefix}.subject`, { subject: emailTemplate.settings.subject })"
                  />
                  <span class="d-block d-md-none font-weight-normal text-truncate">{{
                    emailTemplate.settings.subject
                  }}</span>
                </span>
                <dropdown-options
                  class="d-block d-md-none"
                  @delete="showDeleteEmailModal(emailTemplate)"
                  @edit="showEditEmailModal(emailTemplate)"
                />
                <div class="d-none d-md-block">
                  <hs-button
                    variant="link"
                    id="delete-email"
                    class="px-0 text-danger font-weight-normal"
                    @click="showDeleteEmailModal(emailTemplate)"
                  >
                    <hs-icon icon="trash-alt" size="17" />
                  </hs-button>
                  <hs-button
                    variant="link"
                    id="edit-email"
                    class="px-0 text-dark font-weight-normal ml-3"
                    @click="showEditEmailModal(emailTemplate)"
                  >
                    <hs-icon icon="pencil" size="17" />
                  </hs-button>
                </div>
              </div>
              <div class="send-moment d-flex align-items-center">
                <hs-icon icon="clock" class="mr-2 lh-150" />
                <span class="moment lh-150">{{ getMomentToSendText(emailTemplate, stage.type) }}</span>
              </div>
              <div class="d-flex justify-content-md-start mt-4 mb-2 pb-1">
                <span class="rate d-flex align-items-center lh-150 mr-3">
                  <hs-icon class="pr-1" icon="location-arrow" size="13" />
                  {{
                    $t(`${prefix}.rate.ctr`, {
                      rate: fixRate(emailTemplate.ctr_rate),
                    })
                  }}
                </span>
                <span class="rate d-flex align-items-center lh-150">
                  <hs-icon class="pr-1" icon="envelope-open" size="13" />
                  {{
                    $t(`${prefix}.rate.opening`, {
                      rate: fixRate(emailTemplate.opening_rate),
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <hs-button
            v-if="stage.order"
            id="create-email"
            variant="outline-primary"
            class="w-100"
            @click="showCreateEmailModal"
          >
            <hs-icon icon="plus" />
            {{ $t(`${prefix}.create_email_btn`) }}
          </hs-button>
          <ConfirmModalDeleteEmail
            icon="trash-alt"
            variant="ok-confirm-modal"
            :customTitle="$t(`${prefix}.delete_confirm_modal.title`)"
            :description="$t(`${prefix}.delete_confirm_modal.description`)"
            :ok-title="$t(`${prefix}.delete_confirm_modal.ok_title`)"
            :cancel-title="$t(`${prefix}.delete_confirm_modal.cancel_title`)"
            :verificationText="$t(`${prefix}.delete_confirm_modal.verification_text`)"
            :id="deleteEmailModalId"
            @ok="deleteEmail"
            @confirm="deleteEmail"
            @cancel="hideDeleteEmailModal(deleteEmailModalId)"
            :prefix="prefix"
          />
          <hsEditEmailModal
            @create="createEmail"
            @edit="editEmail"
            :id="editEmailModalId"
            :stage="stage"
            :funnel="funnel"
            :email="emailTemplateToEdit"
          />
        </div>
      </template>
    </SectionContainer>
  </div>
</template>

<script>
import funnelApi from '@/sparkfunnels/services/funnel';
import { hsLoading } from '@/components';
import ConfirmModalDeleteEmail from '@/sparkfunnels/components/ConfirmModalDeleteEmail';
import ToastHelper from '@/shared/helpers/toast';
import { sortEmailTemplates } from '@/sparkfunnels/helpers/sort';
import dayjs from 'dayjs';
import DropdownOptions from './DropdownOptions';
import SectionContainer from '../SectionContainer';
import EmailTemplateMixin from '@/sparkfunnels/mixins/EmailTemplate';
import hsEditEmailModal from '@/sparkfunnels/components/EditEmailModal';
import TrackingHelper from '@/shared/helpers/tracking';
import MessageEmailNotEdited from '@/sparkfunnels/components/MessageEmailNotEdited';

export default {
  components: {
    DropdownOptions,
    ConfirmModalDeleteEmail,
    hsEditEmailModal,
    SectionContainer,
    hsLoading,
    MessageEmailNotEdited,
  },
  mixins: [EmailTemplateMixin],
  props: {
    stage: {
      type: Object,
      required: true,
    },
    funnel: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    customClasses: {
      type: String,
      default: 'mb-md-4',
    },
  },
  data() {
    return {
      isLoading: false,
      emails: [],
      emailTemplateToDelete: null,
      emailTemplateToEdit: null,
      emailCreated: null,
    };
  },
  created() {
    this.loadEmailTemplates();
  },
  watch: {
    emailCreated: function(newValueEmailCreated) {
      const { always_notify_all, days, hours, id, minutes, send_at, settings, status } = newValueEmailCreated;
      this.editEmail({ always_notify_all, days, hours, id, minutes: minutes + 1, send_at, settings, status }, false);
    },
  },
  methods: {
    loadEmailTemplates() {
      this.isLoading = true;
      funnelApi
        .getEmailTemplates(this.funnel.id, this.stage.id, 1, 50)
        .then(({ data }) => {
          this.emails = sortEmailTemplates(
            data.email_templates.map(email => ({
              ...email,
              send_at: email.send_at ? dayjs(email.send_at).toDate() : null,
            }))
          );
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.action.fetch.error`)))
        .finally(() => (this.isLoading = false));
    },
    deleteEmail() {
      this.isLoading = true;
      funnelApi
        .deleteEmailTemplate({
          funnelId: this.funnel.id,
          stageId: this.stage.id,
          emailTemplateId: this.emailTemplateToDelete.id,
        })
        .then(() => {
          this.trackRemoval(this.emailTemplateToDelete);
          this.emailTemplateToDelete = null;
          ToastHelper.successMessage(this.$t(`${this.prefix}.action.delete.success`));
          this.loadEmailTemplates();
          this.refreshStagesEmailContent();
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.action.delete.error`)))
        .finally(() => (this.isLoading = false));
    },
    createEmail(emailTemplate) {
      this.isLoading = true;
      funnelApi
        .createEmailTemplate({ funnelId: this.funnel.id, stageId: this.stage.id, emailTemplate })
        .then(response => {
          ToastHelper.successMessage(this.$t(`${this.prefix}.action.create.success`));
          this.trackCreation(emailTemplate);
          this.loadEmailTemplates();
          this.emailCreated = response.data;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.action.create.error`)))
        .finally(() => (this.isLoading = false));
    },
    editEmail(emailTemplate, showMessage = true) {
      this.isLoading = true;
      funnelApi
        .updateEmailTemplate({ funnelId: this.funnel.id, stageId: this.stage.id, emailTemplate })
        .then(() => {
          if (showMessage) ToastHelper.successMessage(this.$t(`${this.prefix}.action.edit.success`));
          this.trackEdition(emailTemplate);
          this.loadEmailTemplates();
          this.refreshStagesEmailContent();
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.action.edit.error`)))
        .finally(() => (this.isLoading = false));
    },
    fixRate(num) {
      if (num) {
        try {
          return Number(num).toFixed(2);
        } catch (error) {
          return 0;
        }
      }
      return 0;
    },
    showCreateEmailModal() {
      this.emailTemplateToEdit = {
        days: 0,
        hours: 0,
        minutes: 0,
        send_at: null,
        settings: { body: '', subject: '' },
        status: null,
        created_by_user: true,
      };
      this.$bvModal.show(this.editEmailModalId);
    },
    showEditEmailModal(emailTemplate) {
      this.emailTemplateToEdit = emailTemplate;
      this.$bvModal.show(this.editEmailModalId);
    },
    showDeleteEmailModal(emailTemplate) {
      this.emailTemplateToDelete = emailTemplate;
      this.$bvModal.show(this.deleteEmailModalId);
    },
    hideDeleteEmailModal(id) {
      this.$bvModal.hide(id);
    },
    trackCreation(emailTemplate) {
      TrackingHelper.trackEmailCreation(
        this.funnel.id,
        this.funnel.template_type,
        this.stage.type,
        emailTemplate.status,
        emailTemplate.always_notify_all
      );
    },
    trackEdition(emailTemplate) {
      TrackingHelper.trackEmailEdition(this.funnel.id, emailTemplate.always_notify_all);
    },
    trackRemoval(emailTemplate) {
      TrackingHelper.trackEmailRemoval(
        this.funnel.id,
        this.funnel.template_type,
        this.stage.type,
        emailTemplate.status,
        !!emailTemplate.created_by_user
      );
    },
  },
  computed: {
    prefix() {
      return 'sparkfunnels.funnels.edit.sections.email_templates';
    },
    deleteEmailModalId() {
      return `delete-email-${this._uid}`;
    },
    editEmailModalId() {
      return `edit-email-${this._uid}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: $grey-32;
}
.title {
  color: $grey-25;
}
.without-email,
.send-moment {
  color: $grey-30;
}
.moment {
  font-size: 12px;
  color: $grey-32;
}

.rate {
  font-size: 12px;
  color: $grey-30;
}

.edit-subject {
  cursor: pointer;
}

.email-template {
  border-bottom: 1px solid $grey-17;
  &:not(:first-child) {
    padding-top: 16px;
  }
  @media only screen and (min-width: $screen-bs-md) {
    border-color: $grey-20;
  }
}

#create-email {
  background-color: transparent;
  border-style: dashed;
  border-width: 1px;
  box-shadow: none;
  margin-top: 20px;
}
</style>
