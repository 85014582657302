<template>
  <div class="publish-container tlw-flex tlw-items-center" :class="{ 'text-danger': !isCheckoutConfigured }">
    <hs-icon class="tlw-pr-2" variant="light" icon="check-circle" v-if="isCheckoutConfigured" />
    <span class="lh-150 tlw-font-normal">
      {{ $t(`sparkfunnels.funnels.edit.stage_page.${url ? 'checkout_configured' : 'checkout_not_configured'}`) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CheckoutStatus',
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  computed: {
    isCheckoutConfigured() {
      return !!this.url;
    },
  },
};
</script>
